import React from "react"

import CookiePolicyPage from "../containers/CookiePolicyPage"

import { Seo } from "components"

const CookiePolicy = () => (
  <>
    <CookiePolicyPage />
  </>
)

export const Head = () => (
  <Seo title="Cookie policy" description="Nuevacode Privacy Policy" />
)

export default CookiePolicy
