import styled from "styled-components"

import { Container } from "components/Layout/styled"

export const CookiePolicyHero = styled.div`
  background-color: #000;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const CookiePolicyTitle = styled.h1`
  font-weight: 700;
  font-size: 60px;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 30px;
  width: 80%;

  @media (max-width: 768px) {
    font-size: 45px;
    margin-bottom: 20px;
  }

  @media (max-width: 580px) {
    width: 60%;
  }
`

export const CookiePolicyImage = styled.img`
  width: 270px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20%;
  z-index: 0;

  @media (max-width: 1300px) {
    width: 210px;
  }

  @media (max-width: 1024px) {
    width: 160px;
    bottom: 15px;
    right: 25px;
  }

  @media (max-width: 425px) {
    width: 85px;
  }
`

export const CookiePolicyBody = styled(Container)`
  padding: 5vh 14px;

  h1 {
    margin-top: 40px;
    margin-bottom: 16px;

    :first-child {
      font-size: 3em;
      margin-bottom: 30px;
    }

    @media (max-width: 425px) {
      font-size: 28px;
      margin-top: 25px;

      :first-child {
        font-size: 32px;
      }
    }
  }

  h2 {
    @media (max-width: 425px) {
      font-size: 22px;
    }
  }

  a {
    text-decoration: underline;
  }

  ul {
    margin: 15px 0;
  }

  li {
    margin-bottom: 15px;
    margin-left: 20px;
    list-style-type: disc;
  }
  p {
    margin: 7px 0;
  }
`
