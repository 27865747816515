import React from "react"
import { Link } from "gatsby"

import { Container } from "components/Layout/styled"
import CookiePolicyIcon from "images/cookie-policy.svg"
import {
  CookiePolicyBody,
  CookiePolicyHero,
  CookiePolicyImage,
  CookiePolicyTitle,
} from "./styled"

const CookiePolicyPage = () => {
  return (
    <>
      <CookiePolicyHero>
        <Container>
          <CookiePolicyTitle>COOKIE POLICY</CookiePolicyTitle>

          <CookiePolicyImage src={CookiePolicyIcon} alt="Cookie policy" />
        </Container>
      </CookiePolicyHero>

      <CookiePolicyBody>
        <h1>Privacy Policy</h1>
        <strong>Last updated: December 15, 2022</strong>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <h2>Interpretation and Definitions</h2>
        <h2>Interpretation</h2>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h2>Definitions</h2>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to Nuevacode, Sepapaja tn 6, Tallinn
              11415, Estonia,.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: Estonia
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
          </li>
          <li>
            <p>
              <strong>Website</strong> refers to Nuevacode, accessible from{" "}
              <Link to="/">https://nuevacode.com/</Link>
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
        <h2>Types of Data Collected</h2>
        <h3>Personal Data</h3>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>
            <p>Email address</p>
          </li>
          <li>
            <p>First name and last name</p>
          </li>
          <li>
            <p>
              Other personal data that you share with us in the Contact Us form
            </p>
          </li>
        </ul>

        <h3>Tracking Technologies and Cookies</h3>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small
            file placed on Your Device. You can instruct Your browser to refuse
            all Cookies or to indicate when a Cookie is being sent. However, if
            You do not accept Cookies, You may not be able to use some parts of
            our Service. Unless you have adjusted Your browser setting so that
            it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <strong>Web Beacons.</strong> Certain sections of our Service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>
        <p>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on Your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close Your web browser.
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>
        <ul>
          <li>
            <p>
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </li>
        </ul>
        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
        <h2>Use of Your Personal Data</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>
            <p>
              <strong>To provide and maintain our Service</strong>, including to
              monitor the usage of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>For the performance of a contract:</strong> the
              development, compliance and undertaking of the purchase contract
              for the products, items or services You have purchased or of any
              other contract with Us through the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application&apos;s push
              notifications regarding updates or informative communications
              related to the functionalities, products or contracted services,
              including the security updates, when necessary or reasonable for
              their implementation.
            </p>
          </li>
          <li>
            <p>
              <strong>To provide You</strong> with news, special offers and
              general information about other goods, services and events which
              we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such
              information.
            </p>
          </li>
          <li>
            <p>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </p>
          </li>
          <li>
            <p>
              <strong>For other purposes</strong>: We may use Your information
              for other purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </p>
          </li>
        </ul>

        <h2>Disclosure of your personal data</h2>
        <p>
          We do not sell or rent our users data to others. However, we may share
          your personal data with our trusted partners, as well as with service
          providers who perform services on our behalf in case you have given us
          your permission.
        </p>
        <p>
          Nuevacode will only disclose personal information to perform the
          marketing goals mentioned above and will take all required steps to
          safeguard your personal data. We guarantee a high-assurance level of
          personal data protection by entering into stringent confidentiality
          agreements with the third parties.
        </p>
        <p>
          Please keep in mind that this website may be hosted outside your
          country, so when necessary, personal information may be transferred
          from one country to another, as wellas your personal information may
          be stored on a server hosted outside your current location. In such a
          case, we are committed to protecting the privacy of your personal data
          by taking all the necessary security procedures.
        </p>
        <ul>
          <li>If required to do so by law</li>
          <li>To comply with legal proceedings</li>
          <li>
            In the event of an emergency, such as physical injury to a person
          </li>
          <li>
            To protect the rights, property or personal safety of Nuevacode and
            its employees
          </li>
          <li>To protect the personal safety of users or the public</li>
        </ul>

        <h2>Data retention time</h2>

        <p>
          We store your personal data for as long as we need it to perform
          marketing analyzes or as long as we run a mutual business
          collaboration with you. We will delete or anonymize such data when it
          is no longer needed, or there is no legal basis to process it.
        </p>
        <p>
          In case we did not start any kind of collaboration, we will keep your
          data for some limited period only to conduct marketing research, as
          well as to contact your company later as our potential partner.
        </p>
        <p>
          Please also note that you can always request us to completely delete
          your personal data.
        </p>
        <h2>Delete Your Personal Data</h2>
        <p>
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </p>
        <p>
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </p>
        <p>
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </p>
        <p>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>

        <h2>Your user rights</h2>

        <p>
          All users are treated equally in compliance with the current Privacy
          Policy and existing legal acts, and have the following rights
          regarding data that we gather:
        </p>
        <ul>
          <li>
            <strong>The right to request</strong> information on how your
            personal data is collected, processed and utilized
          </li>
          <li>
            <strong>The right to request</strong> access to the data that we
            acquire
          </li>
          <li>
            <strong>The right to request</strong> the erasure of the
            information, which means removing all data related to a user
          </li>
          <li>
            <strong>The right to withdraw</strong> consent to the processing of
            marketing data at any time
          </li>
          <li>
            <strong>The right to object</strong> to our operations related to
            your personal information
          </li>
          <li>
            <strong>The right to restrict</strong> the processing of your
            personal data
          </li>
          <li>
            <strong>The right to data portability</strong>, which means the
            possibility to export your personal data to the digital format
          </li>
          <li>
            <strong>The right to rectification</strong>, which means you can
            command us to correct your personal information in case such
            information is inaccurate or outdated
          </li>
          <li>
            <strong>The right to appeal</strong> to the supervisory authority if
            you believe that the processing of your personal data violates the
            rules of the GDPR
          </li>
        </ul>

        <h2>
          Your California Privacy Rights (California&apos;s Shine the Light law)
        </h2>
        <p>
          This section applies only to California consumers and describes how we
          gather, utilize and share California consumers Personal Information.
          Under California Civil Code Section 1798 (California&apos;s Shine the
          Light law), if you are a California resident who has established
          business collaboration with Nuevacode, you can request information
          once a year about the data we share with other organizations for their
          marketing purposes. Please contact us at{" "}
          <strong>contact@nuevacode.com</strong>
        </p>

        <h2>
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)
        </h2>
        <p>
          California Business and Professions Code section 22581 allows
          California residents under the age of 18 who are registered users of
          online sites, services or applications to request and obtain removal
          of content or information they have publicly posted.
        </p>
        <p>
          To erase such information, you can contact us at
          <strong>contact@nuevacode.com</strong>
          and include the email address associated with your account.
        </p>
        <p>
          Be aware that your request does not guarantee the complete or
          comprehensive removal of content or information posted online and that
          the law may not permit or require removal under certain circumstances.
        </p>

        <h2>Children&apos;s Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 16. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 16. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 16 without verification of parental consent, We take
          steps to remove that information.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>
            By email: <strong>contact@nuevacode.com</strong>
          </li>
        </ul>
      </CookiePolicyBody>
    </>
  )
}

export default CookiePolicyPage
